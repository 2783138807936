<template>
  <div class="overlay">
    <Loading />
  </div>
</template>
<script>
import { checkIfUserExists } from "../api/userController";

export default {
  name: "Invitation",
  methods: {
    async hasAccountAlready() {
      if (this.$route.params.email) {
        const userExists = await checkIfUserExists(this.$route.params.email);
        console.log(userExists);
        if (userExists) {
          return this.$router.push("/");
        }
        return this.$router.push(
          `/register/${this.$route.params.hash}/${this.$route.params.email}`
        );
      }
    },
  },
  mounted() {
    this.hasAccountAlready();
    if (this.$route.params.locale) {
      this.$i18n.locale = this.$route.params.locale;
    }
  },
};
</script>
<style scoped>
.overlay {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
